:root {
  --l1primary: #000;
  --l1secondary: #282828;
  --l1tertiary: #333333;

  --l2primary: #ffffff;
  --l2secondary: #ccc;
  --l2tertiary: #cccccc;

  --l1fontsize: 1.25em;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #333a40;
  background: #f8f9fa;
  min-height: 100vh;
}

.css-1uccc91-singleValue {
  background-color: #d4d4d4;
  color: "#FFF" !important;
  padding: 3px;
  transform: none !important;
  position: relative !important;
}

.spanClickable {
  color: #007bff !important;
  cursor: pointer;
  margin-right: 15px;
}

.spanClickable:hover {
  text-decoration: underline;
}

.bg-dark {
  background: var(--l1tertiary) !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
